import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import '../../Css/Components/footer.css';
import Logo from '../../assets/Logos/Logo.png';
import { FaFacebookF, FaInstagram, FaLinkedin, FaYoutube, } from 'react-icons/fa';



const Footer = () => {
  const [latestVersion, setLatestVersion] = useState('Loading...');

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, 'updates'), orderBy('createdAt', 'desc'), limit(1)),
      (querySnapshot) => {
        const latestUpdate = querySnapshot.docs[0]?.data();
        setLatestVersion(latestUpdate?.version || 'N/A');
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={Logo} alt="Logo" className="footer-logo" />
        <div className="footer-links">
          <Link to="/">Home</Link>
          <Link to="/login">Login</Link>
          <Link to="/about">About</Link>
          <br/>
          <Link to="/blog">Blog</Link>
          <Link to="/login">Faq</Link>
          <Link to="/about">Updates</Link>
          <br/>
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/updates">Get Started</Link>
          <br/>
          {/* <Link to="/more-links">SmeadIT Staff</Link> 
          <Link to="/more-links">SmeadIT Information</Link> 
          <Link to="/more-links">Jobs</Link>  */}
          <br/>
         
        </div>
        <div className="footer-version">
          <p>Version: {latestVersion}</p>
        </div>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61555296355338" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="https://www.instagram.com/darcy.business/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.linkedin.com/in/darcy-smead-a342b230b/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        </div>


        <div className="footer-copy">
          <p>&copy; {new Date().getFullYear()} SMEADIT PTY LTD. All Rights Reserved.</p>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          <p>ABN: 34174336440</p>
          <p>Contact us: Email: <a href="mailto:support@smeadit.com.au">support@smeadit.com.au</a>|| Phone: <a id='tab' href="tel:61481675770">61 481 675 770</a></p>
        </div>
      </div>

      {/* <section className="app-download-section">
        <h2>Download Our Mobile App</h2>
        <p>Get the power of Smeadit in the palm of your hand.</p>
        <div className="download-buttons">
          <button className="download-button apple">
            <i className="fab fa-apple"></i>
            Download on the App Store
          </button>
          <button className="download-button android">
            <i className="fab fa-google-play"></i>
            Get it on Google Play
          </button>
        </div>
      </section> */}
    </footer>
  );
};

export default Footer;
