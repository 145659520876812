
import React, { useState, useEffect } from 'react';
import { firestore } from '../components/firebase';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import Modal from 'react-modal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// -- MODAL STYLES (For React Modal)
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    borderRadius: '20px',
    padding: '2rem',
    boxShadow: '0 10px 25px rgba(0,0,0,0.2)',
    background: '#fff',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

// Make app element accessible for screen readers
Modal.setAppElement('#root');

const UserGoals = () => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch goals from Firestore
  useEffect(() => {
    const goalsQuery = query(
      collection(firestore, 'goals2025'),
      orderBy('createdAt', 'desc')
    );
    const unsubscribe = onSnapshot(
      goalsQuery,
      (snapshot) => {
        const goalsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            title: data.title || '',
            description: data.description || '',
            targetDate: data.targetDate || '',
            completed: data.completed || false,
            priority: data.priority || 'medium',
            metrics: data.metrics || { budget: 0, impact: 0, effort: 0 },
          };
        });
        setGoals(goalsData);
      },
      (error) => {
        console.error('Error fetching goals:', error);
      }
    );

    return () => unsubscribe();
  }, []);

  // Calculate completion percentage
  const calculateCompletion = (goal) => {
    const { metrics, completed } = goal;
    const total = (metrics.budget || 0) + (metrics.impact || 0) + (metrics.effort || 0);

    // If "completed" is true, mark the entire sum as done, otherwise 0.
    // Feel free to adjust logic to partially reflect metrics if that's desired.
    const done = completed ? total : 0;

    return total === 0 ? 0 : Math.round((done / total) * 100);
  };

  // Open Modal
  const openModal = (goal) => {
    setSelectedGoal(goal);
    setIsModalOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedGoal(null);
  };

  return (
    <div className="goals-page">
      <h1 className="page-title">SmeadIT Vision 2025 Goals</h1>

      {/* MAIN BUBBLES */}
      <div className="bubbles-container">
        {goals.map((goal) => {
          const bubbleSize = Math.random() * 80 + 60; // Random bubble sizes
          const randomTop = Math.random() * 80;       // Random positions
          const randomLeft = Math.random() * 80;
          const bubbleClass = `goal-bubble priority-${goal.priority}`;

          return (
            <div
              key={goal.id}
              className={bubbleClass}
              onClick={() => openModal(goal)}
              style={{
                width: bubbleSize,
                height: bubbleSize,
                top: `${randomTop}%`,
                left: `${randomLeft}%`,
                animationDuration: `${Math.random() * 15 + 10}s`,
              }}
            >
              {/* You could display just the title or an icon */}
              <div className="bubble-content">{goal.title}</div>
            </div>
          );
        })}
      </div>

      {/* MODAL */}
      {selectedGoal && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customModalStyles}
          contentLabel="Goal Details"
        >
          <div className="modal-header">
            <h2 className="modal-title">{selectedGoal.title}</h2>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
          </div>

          <div className="modal-content">
            <p className="goal-description">{selectedGoal.description}</p>
            <div className="metrics-info">
              <div className="metric-item">
                <span>Budget:</span>
                <span>${selectedGoal.metrics.budget || 0}</span>
              </div>
              <div className="metric-item">
                <span>Impact:</span>
                <span>{selectedGoal.metrics.impact || 0}</span>
              </div>
              <div className="metric-item">
                <span>Effort:</span>
                <span>{selectedGoal.metrics.effort || 0}</span>
              </div>
            </div>

            <div className="completion-section">
              <div style={{ width: 90, height: 90 }}>
                <CircularProgressbar
                  value={calculateCompletion(selectedGoal)}
                  text={`${calculateCompletion(selectedGoal)}%`}
                  styles={buildStyles({
                    textSize: '16px',
                    pathColor: `rgba(62, 152, 199, ${
                      calculateCompletion(selectedGoal) / 100
                    })`,
                    textColor: '#3e98c7',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                  })}
                />
              </div>
              <span className="completion-label">Completion</span>
            </div>

            <div className="target-info">
              <span>Target Date:</span>{' '}
              <strong>
                {selectedGoal.targetDate
                  ? new Date(selectedGoal.targetDate).toLocaleDateString()
                  : 'No date set'}
              </strong>
            </div>

            <div className={`priority-badge ${selectedGoal.priority}`}>
              {selectedGoal.priority.toUpperCase()}
            </div>
          </div>
        </Modal>
      )}

      {/* STYLE BLOCK */}
      <style jsx>{`
        /* PAGE WRAPPER */
        .goals-page {
          position: relative;
          width: 100%;
          min-height: 100vh;
          overflow: hidden;
          background: linear-gradient(135deg, #f6f9fc, #e9eff5);
          padding: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        /* PAGE TITLE */
        .page-title {
          margin: 0;
          margin-bottom: 2rem;
          color: #333;
          font-size: 2.5rem;
          text-align: center;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
          z-index: 2;
        }

        /* BUBBLES CONTAINER */
        .bubbles-container {
          position: relative;
          width: 100%;
          height: 80vh;
          overflow: hidden;
          z-index: 1;
        }

        /* BUBBLE STYLES */
        .goal-bubble {
          position: absolute;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
          transition: transform 0.4s ease, box-shadow 0.4s ease;
          animation: float 20s infinite ease-in-out;
          padding: 0.5rem;
        }
        .goal-bubble:hover {
          transform: scale(1.08);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
        }
        /* Priority Colors */
        .priority-high {
          background: rgba(244, 67, 54, 0.7);
        }
        .priority-medium {
          background: rgba(255, 152, 0, 0.7);
        }
        .priority-low {
          background: rgba(76, 175, 80, 0.7);
        }

        /* BUBBLE CONTENT */
        .bubble-content {
          font-size: 0.9rem;
          text-align: center;
          padding: 0.5rem;
          line-height: 1.2;
        }

        /* FLOAT ANIMATION */
        @keyframes float {
          0% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(15px);
          }
          100% {
            transform: translateY(0) translateX(0);
          }
        }

        /* MODAL */
        .modal-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
        .modal-title {
          font-size: 1.8rem;
          color: #333;
          margin: 0;
        }
        .close-modal {
          background: none;
          border: none;
          font-size: 2rem;
          color: #999;
          cursor: pointer;
        }
        .close-modal:hover {
          color: #666;
        }

        .modal-content {
          padding-top: 0.5rem;
        }

        .goal-description {
          font-size: 1rem;
          color: #555;
          margin-bottom: 1rem;
          line-height: 1.5;
        }

        .metrics-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2rem;
        }
        .metric-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 0.9rem;
          color: #333;
          min-width: 70px;
        }
        .metric-item span:first-child {
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        .completion-section {
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;
          gap: 1rem;
        }
        .completion-label {
          font-size: 1rem;
          color: #555;
        }

        .target-info {
          font-size: 0.9rem;
          color: #666;
          margin-bottom: 1rem;
        }

        /* PRIORITY BADGE */
        .priority-badge {
          display: inline-block;
          padding: 0.4rem 1rem;
          border-radius: 999px;
          font-size: 0.8rem;
          text-transform: uppercase;
          font-weight: 600;
          color: #fff;
        }
        .priority-badge.high {
          background-color: #f44336;
        }
        .priority-badge.medium {
          background-color: #ff9800;
        }
        .priority-badge.low {
          background-color: #4caf50;
        }
      `}</style>
    </div>
  );
};

export default UserGoals;
