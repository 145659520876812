// WebForm.js
import React, { useState, useEffect, useRef } from 'react';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp
} from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust import path if needed
import './webForm.css';

function WebForm() {
  // -------------------------------
  // HIGH-LEVEL STATES & CONSTANTS
  // -------------------------------
  const [mode, setMode] = useState(''); // '' | 'submit' | 'check'
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const storage = getStorage();

  // For "Check Existing Design" mode
  const [checkEmail, setCheckEmail] = useState('');
  const [projectsFound, setProjectsFound] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);

  // -------------------------------
  // FORM WIZARD STATES
  // -------------------------------
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 7;
  const summaryRef = useRef(null);

  // Web design form data
  const [formData, setFormData] = useState({
    // Step 1: Client Details
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    businessName: '',
    websiteURL: '',

    // Step 2: Color Palette
    selectedPalette: '',
    customPrimary: '#4a90e2',
    customSecondary: '#1454a8',
    customAccent: '#f39c12',

    // Step 4: Typography
    headingFont: '',
    subheadingFont: '',
    bodyFont: '',
    baseFontSize: '16px',
    headingColor: '#333333',
    subheadingColor: '#555555',
    bodyColor: '#333333',

    // Step 5: Additional Features
    features: [],
    extraNotes: '',

    // Step 6: Media
    logoFile: null,
    logoPlacement: '',
    bannerFile: null,
    bannerPlacement: '',

    // Advanced fields
    developerNotes: '',
    progress: 0,
    status: 'pending',
    timeFrame: '', // e.g. "2 weeks"
  });

  // Dynamic pages (Step 3) and additional images (Step 6)
  const [pages, setPages] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);

  // Font selection toggles
  const [showHeadingFonts, setShowHeadingFonts] = useState(true);
  const [showSubheadingFonts, setShowSubheadingFonts] = useState(true);
  const [showBodyFonts, setShowBodyFonts] = useState(true);

  // Available font options
  const fontOptions = [
    'Roboto',
    'Lato',
    'Merriweather',
    'Open Sans',
    'Oswald',
    'Poppins',
    'Raleway',
    'Arial',
  ];

  // -------------------------------
  // MODE SELECTION HANDLERS
  // -------------------------------
  const handleModeSelect = (selectedMode) => {
    setMode(selectedMode);
    setProjectsFound([]);
    setCheckEmail('');
  };

  // -------------------------------
  // CHECK EXISTING DESIGN
  // -------------------------------
  const handleCheckDesign = async () => {
    if (!checkEmail.trim()) {
      alert('Please enter the email you used for your submission.');
      return;
    }
    try {
      setCheckLoading(true);
      setProjectsFound([]);
      const q = query(
        collection(firestore, 'webFormSubmissions'),
        where('clientEmail', '==', checkEmail.trim())
      );
      const snapshot = await getDocs(q);
      const results = [];
      snapshot.forEach((docSnap) => {
        results.push({ id: docSnap.id, ...docSnap.data() });
      });
      setProjectsFound(results);
    } catch (error) {
      console.error('Error fetching existing designs:', error);
      alert('Failed to retrieve your project(s). Try again later.');
    } finally {
      setCheckLoading(false);
    }
  };

  // -------------------------------
  // FORM WIZARD NAVIGATION
  // -------------------------------
  const handleNext = async () => {
    if (currentStep === totalSteps - 1) {
      // Final step – submit the form
      await handleSubmit();
    } else {
      if (validateStep(currentStep)) {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) setCurrentStep((prev) => prev - 1);
  };

  const validateStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        if (
          !formData.clientName.trim() ||
          !formData.clientEmail.trim() ||
          !formData.businessName.trim()
        ) {
          alert('Please fill out required fields: Name, Email, Business.');
          return false;
        }
        return true;
      case 1:
        if (!formData.selectedPalette) {
          alert('Please select or customize a color palette.');
          return false;
        }
        return true;
      case 3:
        if (!formData.headingFont || !formData.subheadingFont || !formData.bodyFont) {
          alert('Please select Heading, Subheading, and Body fonts.');
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  // -------------------------------
  // FORM SUBMISSION
  // -------------------------------
  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitSuccess(null);
    try {
      if (summaryRef.current) {
        summaryRef.current.innerHTML = generateSummaryHTML();
      }
      await uploadImages();

      // Notify admin about a new design request
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'New Website Design Request',
        customerName: formData.clientName,
        customerEmail: formData.clientEmail,
        businessName: formData.businessName,
        selectedPalette: formData.selectedPalette,
        features: formData.features,
        timestamp: serverTimestamp(),
        additionalInfo: formData.extraNotes,
        pages: pages.length,
        read: false,
      });

      // Create the project submission
      const firestoreFormData = {
        ...formData,
        logoFile: formData.logoFile ? formData.logoFile.name : null,
        bannerFile: formData.bannerFile ? formData.bannerFile.name : null,
        additionalImages: additionalImages.map((img) => ({
          ...img,
          file: img.file ? img.file.name : null,
        })),
        pages,
        submittedAt: new Date(),
        lastModified: serverTimestamp(),
      };

      await addDoc(collection(firestore, 'webFormSubmissions'), firestoreFormData);
      setSubmitSuccess(true);
      alert('Form submitted successfully! Your images have been uploaded.');
    } catch (err) {
      setSubmitSuccess(false);
      console.error('Error submitting form:', err);
      alert('Submission failed. Please try again or contact support.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // -------------------------------
  // STORAGE UPLOADS
  // -------------------------------
  const uploadImages = async () => {
    // Upload Logo
    if (formData.logoFile) {
      const file = formData.logoFile;
      const logoRef = storageRef(
        storage,
        `UserWebsiteImages/${formData.clientEmail}_logo_${Date.now()}_${file.name}`
      );
      await uploadBytes(logoRef, file);
      console.log('Logo uploaded to =>', await getDownloadURL(logoRef));
    }
    // Upload Banner
    if (formData.bannerFile) {
      const file = formData.bannerFile;
      const bannerRef = storageRef(
        storage,
        `UserWebsiteImages/${formData.clientEmail}_banner_${Date.now()}_${file.name}`
      );
      await uploadBytes(bannerRef, file);
      console.log('Banner uploaded to =>', await getDownloadURL(bannerRef));
    }
    // Upload Additional Images
    for (let i = 0; i < additionalImages.length; i++) {
      const imgObj = additionalImages[i];
      if (imgObj.file) {
        const adRef = storageRef(
          storage,
          `UserWebsiteImages/${formData.clientEmail}_img${i + 1}_${Date.now()}_${imgObj.file.name}`
        );
        await uploadBytes(adRef, imgObj.file);
        console.log(`Additional image ${i + 1} uploaded =>`, await getDownloadURL(adRef));
      }
    }
  };

  // -------------------------------
  // SUMMARY GENERATION
  // -------------------------------
  const generateSummaryHTML = () => {
    let html = `<h3 class="cw-summary-title">Review Your Selections</h3>`;
    html += `<div class="cw-summary-item"><strong>Client Name:</strong> ${formData.clientName}</div>`;
    html += `<div class="cw-summary-item"><strong>Email:</strong> ${formData.clientEmail}</div>`;
    html += `<div class="cw-summary-item"><strong>Business Name:</strong> ${formData.businessName}</div>`;
    html += `<div class="cw-summary-item"><strong>Website URL:</strong> ${formData.websiteURL || 'N/A'}</div>`;
    html += `<hr/><div class="cw-summary-item"><strong>Selected Palette:</strong> ${formData.selectedPalette || 'None'}</div>`;
    if (formData.selectedPalette === 'custom') {
      html += `<div class="cw-summary-item"><strong>Primary:</strong> ${formData.customPrimary}</div>`;
      html += `<div class="cw-summary-item"><strong>Secondary:</strong> ${formData.customSecondary}</div>`;
      html += `<div class="cw-summary-item"><strong>Accent:</strong> ${formData.customAccent}</div>`;
    }
    html += `<hr/><div class="cw-summary-item"><strong>Website Pages:</strong></div>`;
    if (!pages.length) {
      html += `<div class="cw-summary-item">No pages added.</div>`;
    } else {
      pages.forEach((p) => {
        html += `<div class="cw-summary-item">• <strong>${p.pageName}</strong>: ${p.pageDesc || ''}</div>`;
      });
    }
    html += `<hr/><div class="cw-summary-item"><strong>Heading Font:</strong> ${formData.headingFont}</div>`;
    html += `<div class="cw-summary-item"><strong>Subheading Font:</strong> ${formData.subheadingFont}</div>`;
    html += `<div class="cw-summary-item"><strong>Body Font:</strong> ${formData.bodyFont}</div>`;
    html += `<div class="cw-summary-item"><strong>Base Font Size:</strong> ${formData.baseFontSize}</div>`;
    html += `<div class="cw-summary-item"><strong>Heading Color:</strong> ${formData.headingColor}</div>`;
    html += `<div class="cw-summary-item"><strong>Subheading Color:</strong> ${formData.subheadingColor}</div>`;
    html += `<div class="cw-summary-item"><strong>Body Color:</strong> ${formData.bodyColor}</div>`;
    html += `<hr/><div class="cw-summary-item"><strong>Additional Features:</strong> ${formData.features.length ? formData.features.join(', ') : 'None'}</div>`;
    html += `<div class="cw-summary-item"><strong>Extra Notes:</strong> ${formData.extraNotes || 'None'}</div>`;
    html += `<hr/><div class="cw-summary-item"><strong>Media & Images:</strong></div>`;
    if (formData.logoFile) {
      html += `<div class="cw-summary-item">Logo: ${formData.logoFile.name}</div>`;
    } else {
      html += `<div class="cw-summary-item">No logo uploaded.</div>`;
    }
    html += `<div class="cw-summary-item">Logo Placement: ${formData.logoPlacement || 'Not specified'}</div>`;
    if (formData.bannerFile) {
      html += `<div class="cw-summary-item">Banner: ${formData.bannerFile.name}</div>`;
    } else {
      html += `<div class="cw-summary-item">No banner uploaded.</div>`;
    }
    html += `<div class="cw-summary-item">Banner Placement: ${formData.bannerPlacement || 'Not specified'}</div>`;
    if (!additionalImages.length) {
      html += `<div class="cw-summary-item">No additional images.</div>`;
    } else {
      additionalImages.forEach((img, idx) => {
        html += `<div class="cw-summary-item">• <strong>${img.imageTitle}</strong> (File: ${img.file ? img.file.name : 'No file chosen'})<br/>Placement: ${img.placement || 'N/A'}</div>`;
      });
    }
    return html;
  };

  // -------------------------------
  // DYNAMIC STYLE UPDATES
  // -------------------------------
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--cw-base-font-size', formData.baseFontSize);
    root.style.setProperty('--cw-heading-color', formData.headingColor);
    root.style.setProperty('--cw-subheading-color', formData.subheadingColor);
    root.style.setProperty('--cw-body-color', formData.bodyColor);
  }, [
    formData.baseFontSize,
    formData.headingColor,
    formData.subheadingColor,
    formData.bodyColor,
  ]);

  // -------------------------------
  // STEP WIZARD RENDERING
  // -------------------------------
  const progressPercent = (currentStep / (totalSteps - 1)) * 100;

  const handleSelectFont = (field, fontName) => {
    setFormData((prev) => ({ ...prev, [field]: fontName }));
    if (field === 'headingFont') setShowHeadingFonts(false);
    if (field === 'subheadingFont') setShowSubheadingFonts(false);
    if (field === 'bodyFont') setShowBodyFonts(false);
  };

  const handleSelectPalette = (paletteName) => {
    setFormData((prev) => ({ ...prev, selectedPalette: paletteName }));
  };

  const updateFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  // Step 3: Pages
  const handleAddPage = () => {
    setPages((prev) => [...prev, { id: Date.now(), pageName: '', pageDesc: '' }]);
  };
  const handleDeletePage = (id) => {
    setPages((prev) => prev.filter((p) => p.id !== id));
  };
  const handlePageField = (id, field, value) => {
    setPages((prev) => prev.map((p) => (p.id === id ? { ...p, [field]: value } : p)));
  };

  // Step 6: Additional Images
  const handleAddImage = () => {
    setAdditionalImages((prev) => [...prev, { id: Date.now(), imageTitle: '', placement: '', file: null }]);
  };
  const handleImageField = (id, field, val) => {
    setAdditionalImages((prev) => prev.map((img) => (img.id === id ? { ...img, [field]: val } : img)));
  };

  const renderFeatureOption = (featureLabel) => {
    const checked = formData.features.includes(featureLabel);
    return (
      <label key={featureLabel} className="cw-feature-option">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            if (e.target.checked) {
              updateFormData('features', [...formData.features, featureLabel]);
            } else {
              updateFormData('features', formData.features.filter((f) => f !== featureLabel));
            }
          }}
        />
        <span>{featureLabel}</span>
      </label>
    );
  };

  // -------------------------------
  // RENDER STEP CONTENT
  // -------------------------------
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="cw-step cw-active" data-step="1">
            <h2>Step 1: Your Details</h2>
            <label>Full Name</label>
            <input type="text" value={formData.clientName} onChange={(e) => updateFormData('clientName', e.target.value)} />
            <label>Email Address</label>
            <input type="email" value={formData.clientEmail} onChange={(e) => updateFormData('clientEmail', e.target.value)} />
            <label>Phone Number (Optional)</label>
            <input type="tel" value={formData.clientPhone} onChange={(e) => updateFormData('clientPhone', e.target.value)} />
            <label>Business Name</label>
            <input type="text" value={formData.businessName} onChange={(e) => updateFormData('businessName', e.target.value)} />
            <label>Existing Website URL (Optional)</label>
            <input type="url" value={formData.websiteURL} onChange={(e) => updateFormData('websiteURL', e.target.value)} />
          </div>
        );
      case 1:
        return (
          <div className="cw-step cw-active" data-step="2">
            <h2>Step 2: Color Palette</h2>
            <p>Select a palette that fits your brand:</p>
            <div className="cw-palette-grid">
              {[
                { name: 'sunset', colors: ['#ff7e5f', '#feb47b', '#ffcc33'] },
                { name: 'ocean', colors: ['#00a8cc', '#007ea7', '#005f73'] },
                { name: 'forest', colors: ['#2e8b57', '#3cb371', '#66cdaa'] },
                { name: 'midnight', colors: ['#2c3e50', '#34495e', '#7f8c8d'] },
                { name: 'pastel', colors: ['#a8e6cf', '#dcedc1', '#ffd3b6'] },
                { name: 'vibrant', colors: ['#ff6f61', '#6b5b95', '#feb236'] },
                { name: 'custom', colors: ['#ffffff', '#cccccc', '#999999'] },
              ].map((palette) => (
                <div
                  key={palette.name}
                  className={`cw-palette-box ${formData.selectedPalette === palette.name ? 'cw-active' : ''}`}
                  onClick={() => handleSelectPalette(palette.name)}
                >
                  <div className="cw-palette-swatch">
                    {palette.colors.map((c, i) => (
                      <div key={i} className="cw-swatch" style={{ background: c }} />
                    ))}
                  </div>
                  <div className="cw-palette-label">{palette.name}</div>
                </div>
              ))}
            </div>
            {formData.selectedPalette === 'custom' && (
              <div className="cw-custom-colors">
                <div className="cw-custom-picker">
                  <label>Primary Color</label>
                  <input type="color" value={formData.customPrimary} onChange={(e) => updateFormData('customPrimary', e.target.value)} />
                </div>
                <div className="cw-custom-picker">
                  <label>Secondary Color</label>
                  <input type="color" value={formData.customSecondary} onChange={(e) => updateFormData('customSecondary', e.target.value)} />
                </div>
                <div className="cw-custom-picker">
                  <label>Accent Color</label>
                  <input type="color" value={formData.customAccent} onChange={(e) => updateFormData('customAccent', e.target.value)} />
                </div>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="cw-step cw-active" data-step="3">
            <h2>Step 3: Website Structure</h2>
            <p>Add the pages you need. Click “Add a Page” to define each section.</p>
            <button type="button" className="cw-btn" onClick={handleAddPage}>Add a Page</button>
            <div id="cw-pagesList">
              {pages.map((pg) => (
                <div key={pg.id} className="cw-page-entry">
                  <button type="button" className="cw-delete-page" onClick={() => handleDeletePage(pg.id)}>Delete</button>
                  <h3>Page</h3>
                  <label>Page Name</label>
                  <input
                    type="text"
                    required
                    value={pg.pageName}
                    onChange={(e) => handlePageField(pg.id, 'pageName', e.target.value)}
                    placeholder="e.g. Home, About, Contact"
                  />
                  <label>Brief Description</label>
                  <textarea
                    rows="2"
                    required
                    value={pg.pageDesc}
                    onChange={(e) => handlePageField(pg.id, 'pageDesc', e.target.value)}
                    placeholder="Describe this page"
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="cw-step cw-active" data-step="4">
            <h2>Step 4: Fonts & Typography</h2>
            <label className="cw-font-label"><strong>Select Heading Font</strong></label>
            {showHeadingFonts ? (
              <div className="cw-font-options">
                {fontOptions.map((font) => {
                  const active = formData.headingFont === font;
                  return (
                    <div
                      key={font}
                      className={`cw-font-option ${active ? 'cw-active' : ''}`}
                      style={{ fontFamily: font === 'Arial' ? 'Arial, sans-serif' : `'${font}', sans-serif` }}
                      onClick={() => handleSelectFont('headingFont', font)}
                    >
                      <input type="radio" name="headingFont" checked={active} onChange={() => {}} />
                      <label>{font}</label>
                      <span className="cw-font-sample">The quick brown fox</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="cw-font-selected">
                <div className="cw-selected-info"><strong>Chosen Heading Font:</strong> {formData.headingFont}</div>
                <button type="button" className="cw-btn-sm" onClick={() => setShowHeadingFonts(true)}>Change</button>
              </div>
            )}

            <label className="cw-font-label"><strong>Select Subheading Font</strong></label>
            {showSubheadingFonts ? (
              <div className="cw-font-options">
                {fontOptions.map((font) => {
                  const active = formData.subheadingFont === font;
                  return (
                    <div
                      key={font}
                      className={`cw-font-option ${active ? 'cw-active' : ''}`}
                      style={{ fontFamily: font === 'Arial' ? 'Arial, sans-serif' : `'${font}', sans-serif` }}
                      onClick={() => handleSelectFont('subheadingFont', font)}
                    >
                      <input type="radio" name="subheadingFont" checked={active} onChange={() => {}} />
                      <label>{font}</label>
                      <span className="cw-font-sample">The quick brown fox</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="cw-font-selected">
                <div className="cw-selected-info"><strong>Chosen Subheading Font:</strong> {formData.subheadingFont}</div>
                <button type="button" className="cw-btn-sm" onClick={() => setShowSubheadingFonts(true)}>Change</button>
              </div>
            )}

            <label className="cw-font-label"><strong>Select Body Font</strong></label>
            {showBodyFonts ? (
              <div className="cw-font-options">
                {fontOptions.map((font) => {
                  const active = formData.bodyFont === font;
                  return (
                    <div
                      key={font}
                      className={`cw-font-option ${active ? 'cw-active' : ''}`}
                      style={{ fontFamily: font === 'Arial' ? 'Arial, sans-serif' : `'${font}', sans-serif` }}
                      onClick={() => handleSelectFont('bodyFont', font)}
                    >
                      <input type="radio" name="bodyFont" checked={active} onChange={() => {}} />
                      <label>{font}</label>
                      <span className="cw-font-sample">The quick brown fox</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="cw-font-selected">
                <div className="cw-selected-info"><strong>Chosen Body Font:</strong> {formData.bodyFont}</div>
                <button type="button" className="cw-btn-sm" onClick={() => setShowBodyFonts(true)}>Change</button>
              </div>
            )}

            <label><strong>Base Font Size</strong></label>
            <select value={formData.baseFontSize} onChange={(e) => updateFormData('baseFontSize', e.target.value)}>
              <option value="14px">14px</option>
              <option value="16px">16px</option>
              <option value="18px">18px</option>
              <option value="20px">20px</option>
              <option value="24px">24px</option>
            </select>
            <div className="cw-custom-picker">
              <label>Heading Color</label>
              <input type="color" value={formData.headingColor} onChange={(e) => updateFormData('headingColor', e.target.value)} />
            </div>
            <div className="cw-custom-picker">
              <label>Subheading Color</label>
              <input type="color" value={formData.subheadingColor} onChange={(e) => updateFormData('subheadingColor', e.target.value)} />
            </div>
            <div className="cw-custom-picker">
              <label>Body Text Color</label>
              <input type="color" value={formData.bodyColor} onChange={(e) => updateFormData('bodyColor', e.target.value)} />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="cw-step cw-active" data-step="5">
            <h2>Step 5: Additional Features</h2>
            <p>Select any extra functionalities:</p>
            <div className="cw-features-grid">
              {renderFeatureOption('Contact Form')}
              {renderFeatureOption('Blog')}
              {renderFeatureOption('E-Commerce')}
              {renderFeatureOption('SEO Optimization')}
              {renderFeatureOption('Social Media Integration')}
              {renderFeatureOption('User Login')}
              {renderFeatureOption('Payment Gateway')}
            </div>
            <label>Additional Requirements / Comments</label>
            <textarea rows="4" placeholder="Provide extra details..." value={formData.extraNotes} onChange={(e) => updateFormData('extraNotes', e.target.value)} />
          </div>
        );
      case 5:
        return (
          <div className="cw-step cw-active" data-step="6">
            <h2>Step 6: Media & Images</h2>
            <p>Upload your logo:</p>
            <div className="cw-file-input" onClick={() => document.getElementById('cw-logoFile').click()}>
              Click to upload your logo
              <input type="file" id="cw-logoFile" accept="image/*" style={{ display: 'none' }} onChange={(e) => { if (e.target.files[0]) updateFormData('logoFile', e.target.files[0]); }} />
            </div>
            <label className="cw-image-desc">Logo Placement</label>
            <input type="text" placeholder="e.g. top-left, footer" value={formData.logoPlacement} onChange={(e) => updateFormData('logoPlacement', e.target.value)} />
            <p>Upload your banner (optional):</p>
            <div className="cw-file-input" onClick={() => document.getElementById('cw-bannerFile').click()}>
              Click to upload your banner
              <input type="file" id="cw-bannerFile" accept="image/*" style={{ display: 'none' }} onChange={(e) => { if (e.target.files[0]) updateFormData('bannerFile', e.target.files[0]); }} />
            </div>
            <label className="cw-image-desc">Banner Placement</label>
            <input type="text" placeholder="e.g. homepage hero" value={formData.bannerPlacement} onChange={(e) => updateFormData('bannerPlacement', e.target.value)} />
            <p>Additional Images:</p>
            <div id="cw-additionalImages">
              {additionalImages.map((img) => (
                <div key={img.id} className="cw-page-entry">
                  <h3>Extra Image</h3>
                  <label>Image Title / Use</label>
                  <input type="text" required placeholder="Image description" value={img.imageTitle} onChange={(e) => handleImageField(img.id, 'imageTitle', e.target.value)} />
                  <label className="cw-image-desc">Where does it go?</label>
                  <input type="text" placeholder="Homepage hero, gallery..." value={img.placement} onChange={(e) => handleImageField(img.id, 'placement', e.target.value)} />
                  <div className="cw-file-input" onClick={() => document.getElementById(`cw-file-${img.id}`).click()}>
                    Click to upload image
                    <input type="file" id={`cw-file-${img.id}`} accept="image/*" style={{ display: 'none' }} onChange={(e) => { if (e.target.files[0]) handleImageField(img.id, 'file', e.target.files[0]); }} />
                  </div>
                </div>
              ))}
            </div>
            <button type="button" className="cw-btn" onClick={handleAddImage}>Add Another Image</button>
          </div>
        );
      case 6:
        return (
          <div className="cw-step cw-active" data-step="7">
            <h2>Step 7: Review & Submit</h2>
            <div className="cw-summary-container" ref={summaryRef}></div>
            <p className="cw-info">Once everything looks good, click “Submit.” We’ll build your dream site!</p>
          </div>
        );
      default:
        return null;
    }
  };

  // -------------------------------
  // MAIN RENDER
  // -------------------------------
  return (
    <div>
      <br />      <br />      <br />
    <div className="cw-container-outer">
      {mode === '' && (
        <div className="mode-select-screen">
            <center>  
         <h1>Website Design Submission</h1>
         <br/>
          <button className="cw-btn big-mode-btn" onClick={() => handleModeSelect('submit')}>Submit a Website Design</button>
          <br/>

            <br/>

          <button className="cw-btn big-mode-btn" onClick={() => handleModeSelect('check')}>Check Existing Design</button>
          </center>
        </div>
      )}

      {mode === 'submit' && (
        <div className="cw-container">
          <div className="cw-header">
            <h1 className="cw-title">Website Design Submission</h1>
            <p className="cw-subheading">Fill out the form to start your project</p>
          </div>
          <div className="cw-progress-container">
            <div className="cw-progress-bar" style={{ width: `${progressPercent}%` }}></div>
          </div>
          <form id="cw-websiteForm" onSubmit={(e) => e.preventDefault()}>
            {renderStep()}
            <div className="cw-btn-container">
              <button type="button" className="cw-btn" disabled={currentStep === 0} onClick={handlePrev}>Previous</button>
              <button type="button" className="cw-btn" onClick={handleNext}>
                {currentStep === totalSteps - 1 ? 'Submit' : 'Next'}
              </button>
            </div>
          </form>
        </div>
      )}

      {mode === 'check' && (
        <div className="check-design-container">
          <h2>Check Your Existing Web Design</h2>
          <p>Enter the email you used when submitting the form.</p>
          <input type="email" placeholder="Your email address" value={checkEmail} onChange={(e) => setCheckEmail(e.target.value)} />
          <button className="cw-btn" onClick={handleCheckDesign}>Check My Design</button>
          {checkLoading && <p className="loadingText">Loading your projects...</p>}
          {projectsFound.length > 0 && (
            <div className="projectsFoundSection">
              <h3>Projects Found:</h3>
              {projectsFound.map((proj) => (
                <div key={proj.id} className="foundProjectCard">
                  <p><strong>Business:</strong> {proj.businessName}</p>
                  <p><strong>Status:</strong> {proj.status || 'pending'}</p>
                  <p><strong>Progress:</strong> {proj.progress || 0}%</p>
                  <p><strong>Time Frame:</strong> {proj.timeFrame || 'Not set'}</p>
                  <p><strong>Last Modified:</strong> {proj.lastModified?.toDate ? proj.lastModified.toDate().toLocaleString() : 'N/A'}</p>
                </div>
              ))}
            </div>
          )}
          {projectsFound.length === 0 && !checkLoading && (
            <p>No matching projects found or no submission yet.</p>
          )}
        </div>
      )}

      {isSubmitting && (
        <div className="cw-loading-overlay">
          <div className="cw-loading-spinner" />
          <p>Submitting your data and uploading images, please wait...</p>
        </div>
      )}
    </div>
    </div>
  );
}

export default WebForm;