import React, { useState } from 'react';
import { FaLightbulb, FaUserFriends, FaBriefcase, FaSignInAlt, FaRegIdCard, FaMedal } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Quote from '../components/Forms/Quote.js';
import Contact from '../components/Forms/Contact.js';

const GetStartedPage = () => {
  const [currentStep, setCurrentStep] = useState('initial');
  const [planType, setPlanType] = useState('');
  const navigate = useNavigate();

  // Handle the initial choice between individual, business, and web developer plans
  const handleInitialChoice = (choice) => {
    setPlanType(choice);
    setCurrentStep('selectAction');
  };

  // Handle the action selection based on the initial plan choice
  const handleActionChoice = (action) => {
    if (action === 'Pricing') {
      navigate('/serviceprices');
    } else if (action === 'Info') {
      navigate('/webdevelopment');
    } else if (action === 'RequestMeeting') {
      navigate('/contact');
    } else if (action === 'Login') {
      navigate('/login');
    } else {
      setCurrentStep(`${planType}_${action}`);
    }
  };

  // Handle pricing option selection
  const handlePricingChoice = (choice) => {
    setCurrentStep(`${planType}_Pricing_${choice}`);
  };

  // Render the initial plan options
  const renderInitialOptions = () => {
    return (
      <div className="info-bubbles">
        <div className="info-bubble">
          <FaUserFriends className="bubble-icon" />
          <h3>Individual Plan</h3>
          <p>Personalized tech support for individuals.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('Individual')}>Select</button>
          <br />
        </div>
        <div className="info-bubble">
          <FaBriefcase className="bubble-icon" />
          <h3>Business Plan</h3>
          <p>Dedicated tech support for small businesses.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('Business')}>Select</button>
          <br />
        </div>
        <div className="info-bubble">
          <FaLightbulb className="bubble-icon" />
          <h3>Web Developer</h3>
          <p>Professional web and app development services.</p>
          <button className="selectionButton" onClick={() => handleInitialChoice('WebDeveloper')}>Select</button>
          <br />
        </div>
      </div>
    );
  };

  // Render actions based on the selected plan
  const renderActionOptions = () => {
    if (planType === 'WebDeveloper') {
      return (
        <div className="info-bubbles">
          <div className="info-bubble">
            <FaLightbulb className="bubble-icon" />
            <h3>See Information</h3>
            <button className="selectionButton" onClick={() => handleActionChoice('Info')}>Select</button>
            <br />
          </div>
          <div className="info-bubble">
            <FaSignInAlt className="bubble-icon" />
            <h3>Log Into Portal</h3>
            <button className="selectionButton" onClick={() => handleActionChoice('Login')}>Select</button>
            <br />
          </div>
          <div className="info-bubble">
            <FaRegIdCard className="bubble-icon" />
            <h3>Request a Meeting</h3>
            <button className="selectionButton" onClick={() => handleActionChoice('RequestMeeting')}>Select</button>
            <br />
          </div>
        </div>
      );
    } else {
      return (
        <div className="info-bubbles">
          <div className="info-bubble">
            <FaRegIdCard className="bubble-icon" />
            <h3>Book a Service</h3>
            <p>Book a tech service at your convenience.</p>
            <button className="selectionButton" onClick={() => handleActionChoice('Book')}>Select</button>
            <br />
          </div>
          <div className="info-bubble">
            <FaMedal className="bubble-icon" />
            <h3>See Pricing and Services</h3>
            <p>Explore our pricing plans and available services.</p>
            <button className="selectionButton" onClick={() => handleActionChoice('Pricing')}>Select</button>
            <br />
          </div>
        </div>
      );
    }
  };

  // Render pricing options
  const renderPricingOptions = () => {
    return (
      <div className="info-bubbles">
        <div className="info-bubble">
          <FaMedal className="bubble-icon" />
          <h3>Monthly Membership</h3>
          <p>Get continuous support with a monthly plan.</p>
          <button className="selectionButton" onClick={() => handlePricingChoice('Monthly')}>Select</button>
          <br />
        </div>
        <div className="info-bubble">
          <FaRegIdCard className="bubble-icon" />
          <h3>One-Time Payment</h3>
          <p>Pay once for a specific service.</p>
          <button className="selectionButton" onClick={() => handlePricingChoice('OneTime')}>Select</button>
          <br />
        </div>
      </div>
    );
  };

  // Render pricing table
  const renderPricingTable = () => {
    const pricingData = {
      Business_Pricing_Monthly: {
        price: '$150/month',
        features: ['Basic Business Support', 'Email and Chat Support', 'Monthly Check-ins']
      },
      Business_Pricing_OneTime: {
        price: '$500 one-time',
        features: ['One-Time Business Support', 'On-site Assistance', 'Follow-up Support']
      },
      Individual_Pricing_Monthly: {
        price: '$50/month',
        features: ['3 Fortnightly Call Outs', '$15 Call Out Fee', 'Network Administration and Security', 'Basic Individual Support', 'Email Assistance']
      },
      Individual_Pricing_OneTime: {
        price: '$100 one-time',
        features: ['One-Time Individual Support', 'Remote Assistance', 'Follow-up Support']
      }
    };

    const planDetails = pricingData[currentStep];

    return (
      <div className="pricing-plan">
        <h3>{`${currentStep.split('_')[2]} Plan`}</h3>
        <p>{`${planDetails.price} - Ideal for ${planType}`}</p>
        <ul>
          {planDetails.features.map((feature, index) => <li key={index}>{feature}</li>)}
        </ul>
        <button>Select Plan</button>
        <br />
      </div>
    );
  };

  // Render content based on the current step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'initial':
        return renderInitialOptions();
      case 'selectAction':
        return renderActionOptions();
      case 'Individual_Book':
      case 'Business_Book':
        navigate('/booking');
      case 'Individual_Pricing':
      case 'Business_Pricing':
        return renderPricingOptions();
      case 'Individual_Pricing_Monthly':
      case 'Business_Pricing_Monthly':
        return renderPricingTable();
      case 'Individual_Pricing_OneTime':
      case 'Business_Pricing_OneTime':
        navigate('/serviceprices');
        return null;
      case 'WebDeveloper_Info':
        navigate('/webdevelopment');
        return null;
      case 'WebDeveloper_Login':
        navigate('/login');
        return null;
      case 'WebDeveloper_RequestMeeting':
        navigate('/contact');
        return null;
      default:
        return <p>Unknown step</p>;
    }
  };
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;

  return (
    <div className='ContainerStarter'>
      <Helmet>
        <title>Select Your Tech Support - Smeadit</title>
        <meta name="description" content="Choose from our options of Individual, Business, and Web Development support plans." />
        <meta name="keywords" content="Smeadit, IT Choice, SmeadIT Tech support, SmeadIT Options, tech support, technology solutions, tech assistance, tech innovations" />
        <meta property="og:title" content="Select Your Tech Support - Smeadit" />
        <meta property="og:description" content="Discover personalized tech support and innovative solutions with Smeadit. Choose from Individual, Business, and Web Development support plans." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/getstarted" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/getstarted" />
      </Helmet>
      <div className="getStartedSection">
        <div className="getStartedContainer">
          {renderCurrentStep()}
          {currentStep !== 'initial' && <button className="cta-button" id='Startover' onClick={() => setCurrentStep('initial')}>Start Over</button>}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default GetStartedPage;
