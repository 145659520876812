import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../Css/Components/Nav.css';
import Logo from '../../assets/Logos/Logo.png';
import { FaHome, FaTools, FaEnvelope, FaChevronDown, FaBars } from 'react-icons/fa';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Book Online');

  const buttonTextOptions = ['Book Online'];

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    const buttonTextChangeInterval = setInterval(() => {
      setButtonText(prevText => {
        const currentIndex = buttonTextOptions.indexOf(prevText);
        const nextIndex = (currentIndex + 1) % buttonTextOptions.length;
        return buttonTextOptions[nextIndex];
      });
    }, 20000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(buttonTextChangeInterval);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  return (
    <div className="navbar">
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <Link to="/"><img src={Logo} alt="Logo" /></Link>
        </div>
        
        <button className="side-nav-toggle" onClick={toggleSideNav}><FaBars /></button>
        <div className={`nav-links ${sideNavOpen ? 'open' : ''}`}>
          <Link to="/" onClick={toggleSideNav}><FaHome /> Home</Link>
          <Link to="/services" onClick={toggleSideNav}><FaTools />Services</Link>
          <Link to="/contact" onClick={toggleSideNav}><FaEnvelope /> Contact</Link>
          <div className="dropdown">
            <button onClick={toggleDropdown}>More <FaChevronDown /></button>
            {dropdownOpen && (
              <div className="dropdown-content">
                <Link to="/about">About</Link> 
                <Link to="/webinars">Webinars</Link>
                <Link to="/blogs">Blog</Link>
                <Link to="/updates">Updates</Link>
              </div>
            )}
          </div>
          <Link to="/booking"><button className="quote-btn" onClick={toggleSideNav}>{buttonText}</button></Link>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
