// CustomerService.js
import React, { useState } from 'react';
import { firestore } from '../firebase'; // Using firestore directly
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { FaStar, FaUser, FaEnvelope, FaPhone, FaPlus, FaCheck } from 'react-icons/fa';

const CustomerService = () => {
  const [step, setStep] = useState(1);
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [referrals, setReferrals] = useState([{ name: '', email: '', phone: '' }]);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // New fields for user identification
  const [userEmail, setUserEmail] = useState('');
  const [anonymous, setAnonymous] = useState(false);

  // Positive/Neutral Feedback Options
  const positiveFeedbackChoices = [
    'Quick Delivery',
    'Good Communication',
    'Quality of Service',
    'Professional Staff',
    'Easy to Use',
    'Great Value',
    'Friendly Support',
    'Timely Updates'
  ];

  // Negative Feedback Options
  const negativeFeedbackChoices = [
    'Poor Communication',
    'Slow Response',
    'Unhelpful Support',
    'Complicated Process',
    'Low Value for Money',
    'Unfriendly Staff'
  ];

  const googleReviewUrl = 'https://g.page/r/CYgA3cXRwLVPEBM/review';

  const showGooglePrompt = rating >= 4;
  const couponEligible = referrals.length >= 2;

  const isLowRating = rating > 0 && rating < 3;
  const isPerfectRating = rating === 5;
  
  const lowRatingDiscountCode = isLowRating ? `SAVE${Math.floor(Math.random() * 900 + 100)}` : '';

  const handleAddReferral = () => {
    if (referrals.length < 5) {
      setReferrals([...referrals, { name: '', email: '', phone: '' }]);
    }
  };

  const handleReferralChange = (index, e) => {
    const { name, value } = e.target;
    const updated = [...referrals];
    updated[index][name] = value;
    setReferrals(updated);
  };

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(o => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleNextStep = () => {
    // Validation for Step 1: Rating and identity choice
    if (step === 1) {
      if (rating === 0) {
        setSubmissionStatus('Please select a rating before proceeding.');
        return;
      }
      if (!anonymous && !userEmail.trim()) {
        setSubmissionStatus('Please provide your email or select anonymous.');
        return;
      }
    }
    setSubmissionStatus('');
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setSubmissionStatus('');
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agreedToTerms) {
      setSubmissionStatus('Please agree to the terms before submitting.');
      return;
    }

    const dataToSubmit = {
      rating,
      feedbackText,
      selectedOptions,
      referrals,
      userEmail: anonymous ? '' : userEmail.trim(),
      timestamp: serverTimestamp()
    };

    try {
      setLoading(true);
      await addDoc(collection(firestore, 'serviceRatings'), dataToSubmit);
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'Service Rating',
        rating,
        referrals,
        timestamp: serverTimestamp(),
        read: false
      });
      setLoading(false);
      setSubmitted(true);
      setSubmissionStatus('Thank you! Your feedback has been recorded.');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setLoading(false);
      setSubmissionStatus('Error submitting. Please try again.');
    }
  };

  if (submitted) {
    return (
      <div style={styles.pageWrapper}>
        <div style={styles.container}>
          <div style={styles.submittedBox}>
            <FaCheck style={{ fontSize: '48px', color: '#4caf50', marginBottom: '10px' }} />
            <h2 style={styles.heading}>Thank You!</h2>
            <p style={styles.paragraph}>{submissionStatus}</p>
            {couponEligible && (
              <div style={styles.couponBox}>
                <strong style={{fontSize:'16px'}}>Congratulations!</strong><br/> You referred 2 or more friends! Enjoy a <strong>$10 coupon</strong> on your next purchase.
              </div>
            )}
            {isLowRating && (
              <div style={styles.couponBox}>
                <strong style={{fontSize:'16px'}}>We’re sorry the experience wasn’t perfect.</strong><br/>
                Use code <strong>{lowRatingDiscountCode}</strong> for a discount on your next service.
              </div>
            )}
            <button onClick={() => { 
              // Reset form if they want to do another submission
              setStep(1);
              setRating(0);
              setFeedbackText('');
              setSelectedOptions([]);
              setReferrals([{ name: '', email: '', phone: '' }]);
              setAgreedToTerms(false);
              setSubmitted(false);
              setSubmissionStatus('');
              setUserEmail('');
              setAnonymous(false);
            }} style={styles.buttonPrimary}>Submit Another</button>
          </div>
        </div>
      </div>
    );
  }

  const renderStepIndicator = () => {
    const steps = ['Rating', 'Options', 'Referrals', 'Review & Submit'];
    return (
      <div style={styles.stepIndicator}>
        {steps.map((s, i) => {
          const currentIndex = i + 1;
          return (
            <div key={s} style={{...styles.stepItem, ...(currentIndex <= step ? styles.stepActive : {})}}>
              <span style={styles.stepNumber}>{currentIndex}</span> {s}
            </div>
          );
        })}
      </div>
    );
  };

  const renderProgressBar = () => {
    const progress = (step / 4) * 100;
    return (
      <div style={styles.progressBarContainer}>
        <div style={{...styles.progressBarFiller, width:`${progress}%`}}></div>
      </div>
    );
  };

  const renderStepContent = () => {
    if (step === 1) {
      return (
        <div style={styles.animationFadeIn}>
          <h2 style={{ ...styles.heading, marginBottom: '20px', fontSize:'24px' }}>Rate Our Service</h2>
          <p style={styles.paragraph}>Your feedback helps us improve.</p>

          {/* Identity Section */}
          <div style={styles.identityBox}>
            <label style={styles.label}>Your Email (or select anonymous)</label>
            <div style={styles.identityRow}>
              <input
                type="email"
                placeholder="your.email@example.com"
                value={userEmail}
                onChange={e=>setUserEmail(e.target.value)}
                disabled={anonymous}
                style={styles.inputBasic}
              />

              <label className="custom-checkbox-container">
                <input
                  type="checkbox"
                  checked={anonymous}
                  onChange={()=>setAnonymous(!anonymous)}
                  className="custom-checkbox-input"
                />
                <span className="custom-checkbox-mark"></span>
                Remain Anonymous
              </label>
              
            </div>
          </div>

          {/* Star Rating, center and bigger */}
          <div style={styles.starContainer}>
            <div style={styles.starRating}>
              {[1,2,3,4,5].map(star => (
                <FaStar
                  key={star}
                  onClick={() => setRating(star)}
                  style={{
                    cursor: 'pointer',
                    fontSize: '32px',
                    color: star <= rating ? '#FFD700' : '#ccc'
                  }}
                />
              ))}
            </div>
          </div>

          {isPerfectRating && (
            <p style={{...styles.paragraph, color:'#0077c2', marginTop:'10px', textAlign:'center'}}>
              Wow, a perfect 5 stars! You’re officially a VIP in our book.
            </p>
          )}

          {isLowRating && (
            <p style={{...styles.paragraph, color:'#d32f2f', marginTop:'10px', textAlign:'center'}}>
              Sorry to hear that. We’ll do better next time!
            </p>
          )}

          <div style={{ marginTop: '20px' }}>
            <label style={styles.label}>Any additional feedback?</label>
            <textarea
              style={styles.textarea}
              value={feedbackText}
              onChange={e => setFeedbackText(e.target.value)}
              placeholder="Your comments here..."
            />
          </div>
          {submissionStatus && (
            <p style={styles.statusMessage}>{submissionStatus}</p>
          )}
        </div>
      );
    } else if (step === 2) {
      const feedbackChoices = isLowRating ? negativeFeedbackChoices : positiveFeedbackChoices;

      return (
        <div style={styles.animationFadeIn}>
          {showGooglePrompt && !isLowRating && (
            <div style={styles.googlePrompt}>
              <h3 style={{...styles.heading, fontSize:'18px', marginBottom:'5px'}}>Enjoying our service?</h3>
              We’re thrilled you had a great experience! Please consider leaving us a review on Google to help others discover our services.<br/>
              <a href={googleReviewUrl} target="_blank" rel="noopener noreferrer" style={styles.link}>
                Leave a Google Review
              </a>
            </div>
          )}

          {isLowRating && (
            <p style={{...styles.paragraph, color:'#d32f2f', marginTop:'10px', fontSize:'15px', fontWeight:'bold'}}>
              We’re sorry about your experience. Could you let us know what went wrong?
            </p>
          )}

          <p style={{...styles.label, marginTop:'20px'}}>{isLowRating ? 'What went wrong?' : 'What stood out? (Optional)'}</p>
          <div style={styles.optionsContainer}>
            {feedbackChoices.map(option => (
              <label key={option} style={styles.optionLabel}>
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionChange(option)}
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div style={styles.animationFadeIn}>
          <h3 style={{ ...styles.heading, fontSize:'20px', marginBottom:'10px' }}>Refer a Friend</h3>
          <p style={styles.paragraph}>Refer 2 or more friends and you'll receive a <strong style={{color:'#1f73b7'}}> $10 coupon</strong> off your next purchase!</p>
          {referrals.map((ref, i) => (
            <div key={i} style={styles.referralBox}>
              <h4 style={{ ...styles.heading, fontSize:'16px', marginBottom:'10px' }}>Referral #{i+1}</h4>
              <div style={styles.inputGroup}>
                <div style={styles.inputWrapper}>
                  <FaUser style={styles.inputIcon}/>
                  <input
                    type="text"
                    name="name"
                    value={ref.name}
                    onChange={(e) => handleReferralChange(i, e)}
                    placeholder="Full Name"
                    required
                    style={styles.input}
                  />
                </div>
                <div style={styles.inputWrapper}>
                  <FaEnvelope style={styles.inputIcon}/>
                  <input
                    type="email"
                    name="email"
                    value={ref.email}
                    onChange={(e) => handleReferralChange(i, e)}
                    placeholder="Email"
                    required
                    style={styles.input}
                  />
                </div>
                <div style={styles.inputWrapper}>
                  <FaPhone style={styles.inputIcon}/>
                  <input
                    type="tel"
                    name="phone"
                    value={ref.phone}
                    onChange={(e) => handleReferralChange(i, e)}
                    placeholder="Phone"
                    required
                    style={styles.input}
                  />
                </div>
              </div>
            </div>
          ))}
          {referrals.length < 5 && (
            <button onClick={handleAddReferral} style={styles.addReferralBtn}>
              <FaPlus /> Add Another Referral
            </button>
          )}
        </div>
      );
    } else if (step === 4) {
      return (
        <div style={styles.animationFadeIn}>
          <h2 style={{ ...styles.heading, marginBottom: '10px' }}>Review Your Submission</h2>
          {!anonymous && userEmail && (
            <p style={styles.paragraph}><strong>Email:</strong> {userEmail}</p>
          )}
          {anonymous && (
            <p style={styles.paragraph}><strong>Email:</strong> Anonymous</p>
          )}
          <p style={styles.paragraph}><strong>Rating:</strong> {rating} Star(s)</p>
          <p style={styles.paragraph}><strong>Feedback:</strong> {feedbackText || 'None provided'}</p>
          {selectedOptions.length > 0 && (
            <p style={styles.paragraph}>
              <strong>{isLowRating ? 'Issues:' : 'What Stood Out:'}</strong> {selectedOptions.join(', ')}
            </p>
          )}
          {referrals.length > 0 && (
            <div style={{ marginTop:'10px' }}>
              <strong>Referrals:</strong>
              {referrals.map((r, i) => (
                <p style={styles.paragraph} key={i}>
                  {r.name} ({r.email}, {r.phone})
                </p>
              ))}
            </div>
          )}
          <div style={styles.termsContainer}>
            <label htmlFor="terms-agreement" style={{ marginRight:'10px', fontSize:'14px', color:'#555' }}>I agree to the terms and conditions</label>
            <input
              type="checkbox"
              id="terms-agreement"
              checked={agreedToTerms}
              onChange={() => setAgreedToTerms(!agreedToTerms)}
            />
          </div>
          {submissionStatus && (
            <p style={styles.statusMessage}>{submissionStatus}</p>
          )}
          <button onClick={handleSubmit} style={styles.buttonSubmit} disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <style>
        {`
          .custom-checkbox-container {
            position: relative;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            cursor: pointer;
            font-size: 14px;
            user-select: none;
            background: #f0f4f8;
            border-radius: 6px;
            border: 1px solid #e0e6ed;
            transition: background 0.2s ease;
          }
          .custom-checkbox-container:hover {
            background: #e5eaf0;
          }
          .custom-checkbox-input {
            appearance: none;
            width: 0;
            height: 0;
            opacity: 0;
            position: absolute;
            pointer-events: none;
          }
          .custom-checkbox-mark {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 2px solid #1f73b7;
            border-radius: 4px;
            background: #fff;
            position: relative;
            transition: all 0.2s ease;
          }
          .custom-checkbox-container:hover .custom-checkbox-mark {
            border-color: #165a90;
          }
          .custom-checkbox-input:checked + .custom-checkbox-mark {
            background: #1f73b7;
          }
          .custom-checkbox-input:checked + .custom-checkbox-mark:after {
            content: "";
            position: absolute;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        `}
      </style>
      <div style={styles.pageWrapper}>
        <div style={styles.container}>
          {renderStepIndicator()}
          {renderProgressBar()}
          <div style={styles.formBox}>
            {renderStepContent()}
            <div style={styles.navigationButtons}>
              {step > 1 && (
                <button onClick={handlePreviousStep} style={styles.buttonBack}>
                  Back
                </button>
              )}
              {step < 4 && (
                <button onClick={handleNextStep} style={styles.buttonPrimary}>
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  pageWrapper: {
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #e0ecf7, #ffffff)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  container: {
    width: '100%',
    maxWidth: '700px',
    margin: '40px auto',
    padding: '20px'
  },
  formBox: {
    background: '#fff',
    borderRadius: '12px',
    padding: '30px',
    boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
    marginTop:'20px',
    position:'relative'
  },
  heading: {
    fontWeight: 'bold',
    color: '#333',
    margin: '0'
  },
  paragraph: {
    color: '#555',
    margin: '0 0 10px 0',
    lineHeight: '1.5',
    fontSize:'14px'
  },
  identityBox: {
    background:'#f9f9f9',
    padding:'15px',
    borderRadius:'8px',
    margin:'20px 0'
  },
  identityRow: {
    display:'flex',
    gap:'10px',
    alignItems:'center',
    marginTop:'10px'
  },
  inputBasic: {
    padding: '12px 15px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    fontSize: '14px',
    flex: '1',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
  },
  starContainer: {
    display:'flex',
    justifyContent:'center',
    marginTop:'20px'
  },
  starRating: {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px'
  },
  googlePrompt: {
    background: '#f0fbff',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
    fontSize: '14px'
  },
  link: {
    display: 'inline-block',
    marginTop: '5px',
    color: '#1f73b7',
    textDecoration: 'underline',
    fontWeight:'bold',
    fontSize:'14px'
  },
  label: {
    fontWeight: '600',
    display: 'block',
    marginBottom: '5px',
    color: '#333',
    fontSize:'14px'
  },
  textarea: {
    width: '100%',
    height: '100px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    padding: '12px',
    fontSize:'14px',
    transition:'border 0.3s'
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginTop:'10px'
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    background:'#f9f9f9',
    padding:'8px 12px',
    borderRadius:'8px',
    fontSize:'14px',
    cursor:'pointer',
    transition:'background 0.3s'
  },
  referralBox: {
    background: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '15px',
    border:'1px solid #eee'
  },
  inputGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px'
  },
  inputWrapper: {
    position: 'relative',
    flex: '1 1 200px'
  },
  inputIcon: {
    position: 'absolute',
    top: '50%',
    left: '12px',
    transform: 'translateY(-50%)',
    color: '#999',
    fontSize:'14px'
  },
  input: {
    width: '100%',
    padding: '10px 10px 10px 36px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    fontSize:'14px',
    transition:'border 0.3s'
  },
  addReferralBtn: {
    background: '#1f73b7',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 16px',
    cursor: 'pointer',
    marginTop: '10px',
    fontSize:'14px',
    display:'flex',
    alignItems:'center',
    gap:'5px'
  },
  termsContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  statusMessage: {
    marginTop: '10px',
    color: '#d32f2f',
    fontSize:'14px'
  },
  buttonSubmit: {
    marginTop: '30px',
    background: '#1f73b7',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius:'8px',
    cursor:'pointer',
    fontSize:'16px',
    fontWeight:'bold',
    transition:'background 0.3s',
    display:'inline-block'
  },
  submittedBox: {
    textAlign:'center',
    background:'#fff',
    padding:'40px',
    borderRadius:'12px',
    boxShadow:'0 5px 20px rgba(0,0,0,0.1)'
  },
  couponBox: {
    marginTop:'20px',
    background:'#e0ffd4',
    padding:'15px',
    borderRadius:'8px',
    fontSize:'14px',
    lineHeight:'1.4'
  },
  buttonPrimary: {
    background:'#1f73b7',
    color:'#fff',
    padding:'10px 20px',
    border:'none',
    borderRadius:'8px',
    cursor:'pointer',
    fontSize:'16px',
    fontWeight:'bold',
    transition:'background 0.3s',
    marginLeft:'10px'
  },
  buttonBack: {
    background:'#555',
    color:'#fff',
    padding:'10px 20px',
    border:'none',
    borderRadius:'8px',
    cursor:'pointer',
    fontSize:'16px',
    fontWeight:'bold',
    transition:'background 0.3s'
  },
  navigationButtons: {
    marginTop:'20px',
    display:'flex',
    justifyContent:'flex-end'
  },
  stepIndicator: {
    display:'flex',
    justifyContent:'space-between',
    fontSize:'14px',
    color:'#333',
    marginBottom:'10px'
  },
  stepItem: {
    display:'flex',
    alignItems:'center',
    gap:'5px',
    color:'#999'
  },
  stepNumber: {
    width:'20px',
    height:'20px',
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',
    background:'#ccc',
    borderRadius:'50%',
    fontSize:'12px',
    color:'#fff',
    fontWeight:'bold'
  },
  stepActive: {
    color:'#1f73b7'
  },
  progressBarContainer: {
    width:'100%',
    height:'8px',
    background:'#eee',
    borderRadius:'4px',
    overflow:'hidden',
    marginBottom:'20px'
  },
  progressBarFiller: {
    height:'100%',
    background:'#1f73b7',
    transition:'width 0.3s ease'
  },
  animationFadeIn: {
    animation:'fadeIn 0.5s ease-in-out'
  }
};

export default CustomerService;
