import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../Css/Pages/Contact.css'; // Ensure this CSS file is properly linked
import { firestore } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send email using emailjs
      await emailjs.sendForm('service_lv1dlfu', 'template_00idw1w', e.target, 'YvWDp1JgzDcm2vsot');
  
      // Add message to Firestore with additional fields
      await addDoc(collection(firestore, "contacts"), {
        ...formData,
        createdAt: serverTimestamp(),
        replied: false,
      });
  
      // Add notification for contact form submission
      await addDoc(collection(firestore, 'adminNotifications'), {
        eventType: 'Contact Form Submission',
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        timestamp: serverTimestamp(),
        read: false,
      });
  
      setMessageSent(true);
      setTimeout(() => setMessageSent(false), 5000);
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}/contact`;

  return (
    <div className="contact-wrapperer">
      <Helmet>
        <title>Contact Us - SmeadIT</title>
        <meta name="description" content="Get in touch with us at SmeadIT for all your tech support needs. Contact us via phone, email, or our contact form." />
        <meta name="keywords" content="SmeadIT contact, SmeadIT support, SmeadIT email, SmeadIT phone, contact smeadit" />
        <meta property="og:title" content="Contact Us - SmeadIT" />
        <meta property="og:description" content="Reach out to SmeadIT for tech support services. Contact us today." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="../assets/Smeadit.png" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" hreflang="en-au" href="https://smeadit.au/contact" />
        <link rel="alternate" hreflang="en" href="https://smeadit.com/contact" />
      </Helmet>
    <div className="contact-wrapper">
    <div className="contact-links">
      <div className="contact-link">
      Call: <a href="tel:+61481675770"> +61 481 675 770</a>
      </div>
 
      <div className="contact-link">
      Email: <a href="mailto:support@smeadit.com.au">Support@smeadit.com.au</a>
      </div>
    </div>
      <div className="contact-container">
        {messageSent && (
          <div className="success-message">
            Your message has been sent successfully!
          </div>
        )}
        <h1>Contact Us </h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Contact;
