import React, { useState, useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { FaDesktop, FaWifi, FaMobile, FaPrint, FaQuestionCircle, FaRecycle, FaShieldAlt, FaUserGraduate, FaToolbox, FaHome, FaCode, FaLaptopCode, FaTabletAlt, FaShoppingCart, FaScrewdriver, FaBug, FaHandsHelping, FaTv, FaWrench, FaClock, FaUserShield, FaSearch, FaCloud, FaGamepad, FaAmbulance, FaMobileAlt, FaBullhorn,} from "react-icons/fa";
import AboutSmeaditSlider from './AboutSmeaditSlider';
import MySVG from '../assets/PriceList.svg'; 
import ServiceList from './Features';
import ServiceIcon1 from '../assets/InpersonPhotos/Norma.jpg'; // Add your service icon paths
import ServiceIcon2 from '../assets/InpersonPhotos/Andy.jpg';
import ServiceIcon3 from '../assets/info.jpg';
import ServiceModal from '../components/Modals/ServiceModal'; // Import the modal component
import placeholderImage from '../assets/info.jpg';
import DropDown from '../components/Features/DropDown';
import '../Css/Pages/Home.css';

const Home = () => {
  const [style, setStyle] = useState({});
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const heroRef = useRef(null);
  const backgroundRef = useRef(null);
  const currentDomain = window.location.hostname;
  const canonicalUrl = `https://${currentDomain}${window.location.pathname}`;

  const handleLearnMore = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleMouseMove = (e) => {
    const width = window.innerWidth / 2;
    const height = window.innerHeight / 2;
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const depth1 = `${50 - (mouseX - width) * 0.02}% ${50 - (mouseY - height) * 0.02}%`;
    const depth2 = `${50 - (mouseX - width) * 0.04}% ${50 - (mouseY - height) * 0.04}%`;
    setStyle({ '--depth-1': depth1, '--depth-2': depth2 });
  };

   useEffect(() => {
    const heroElement = heroRef.current;
    const backgroundElement = backgroundRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const heroHeight = heroElement.offsetHeight;
      const scrollPercentage = Math.min(scrollPosition / heroHeight, 1);
    
      // Subtle parallax effect
      backgroundElement.style.transform = `translateY(${scrollPosition * 0.2}px)`;
    
      // Fade effect
      backgroundElement.style.opacity = 1 - scrollPercentage;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home">
      <section className="Homehero" ref={heroRef}>
        <div className="Homehero-background" ref={backgroundRef}></div>
        <div className="Homehero-content">
        <Helmet>
            <title>Smeadit Tech Support</title>
            <meta name="description" content="SmeadIt are your local turramurra techies, that offer personalized tech support and innovative solutions to enhance your technology experience. Explore our services today." />
            <meta name="keywords" content="Smeadit, SmeadIT home, SmeadIT Tech support, SmeadIt login, tech support, technology solutions, tech assistance, tech innovations" />
            <meta property="og:title" content="Welcome to Smeadit - Enhancing Your Tech Experience" />
            <meta property="og:description" content="Discover personalized tech support and innovative solutions with Smeadit. Your technology experience, redefined." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:image" content="../assets/Smeadit.png" />
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" hreflang="en-au" href="https://smeadit.au/" />
            <link rel="alternate" hreflang="en" href="https://smeadit.com/" />
        </Helmet>
   
          <h1>Welcome to SmeadIT </h1>
          <p>We Are Local Turramurra Techies That Help You With All Things Technology!</p>
          <div className="info-bubbles">
            <Link to="/booking">
              <div className="info-bubble">
                <FaWrench className="bubble-icon" />
                <h3 className='noLine'>Tech Support</h3>
                <p className='noLine'>Click to schedule a tech support session.</p>
              </div>
            </Link>
            <Link to="/getstarted">
            <div className="info-bubble">
              <FaMobile className="bubble-icon" />
              <h3>Need Help</h3>
              <p>Click here to find your tech service.</p>
            </div>
            </Link>
            <Link to="/contact">
              <div className="info-bubble">
                <FaClock className="bubble-icon" />
                <h3>Contact us</h3>
                <p>Having trouble? Call us: <a id='tab' href="tel:61479171650">+61 481 675 770</a></p>
              </div>
            </Link>
          </div>
          <div className="button-container">
            <br></br>
  <Link to="/booking"><button className="cta-button">Book Support</button></Link>
  
</div>

        </div>
      </section>

      <section className="servicehomepagesection-section" id="services-section">
  <div className="servicehomepagesection-content">
    <div className="servicehomepagesection-text">
      <h2>What We Offer!</h2>
      <p>Explore our large range of technology services designed to meet all your needs.</p>
    </div>

    
    <div className="servicehomepagesection-container">
    <Link to='./services'>
      <div className="servicehomepagesection-item">
        <FaToolbox className="servicehomepagesection-icon" />
        <h3>Business Tech Support</h3>
        <p>Comprehensive solutions for your business IT needs.</p>
        <button className="servicehomepagesection-btn">Find out more</button>
      </div>
      </Link>
      <Link to='./services'>
      <div className="servicehomepagesection-item">
        <FaHandsHelping className="servicehomepagesection-icon" />
        <h3>Personal Tech Support</h3>
        <p>Reliable IT support for your personal devices.</p>
        <button className="servicehomepagesection-btn">Find out more</button>
      </div>
      </Link>
      <Link to='./webdevelopment'>
      <div className="servicehomepagesection-item">
        <FaLaptopCode className="servicehomepagesection-icon" />
        <h3>Web + App Development</h3>
        <p>Affordable and customisable websites for your business.</p>
        <button className="servicehomepagesection-btn">Find out more</button>
      </div>
      </Link>
     <Link to='./webinars'>
      <div className="servicehomepagesection-item">
        <FaUserGraduate className="servicehomepagesection-icon" />
        <h3>Weekly Tech Webinars</h3>
        <p>Guided computer courses to help improve you tech skills.</p>
        <button className="servicehomepagesection-btn">Find out more</button>
      </div>
      </Link>
    </div>
  </div>
</section>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>


      <section className="info-section">
      <br/><br/> 
      <h1 className='titleName'>How it works!</h1>
      <br/><br/>

        <div className="info-block">
          <div className="info-image-container">
            <img src={ServiceIcon1} alt="Advanced Technology Support" className="info-image" />
          </div>
          <div className="info-text-container">
            <h2>Need Help with Technology?</h2>
            <p>SmeadIT is here to assist you. We offer a wide range of services to meet your needs. Simply click the button below to see your options, or contact us if you have any questions.</p>
            <Link to="/getstarted"><button className="service-btn">See Your Options</button></Link>
          </div>
        </div>
        <div className="info-block middle-block">
          <div className="info-text-container">
            <h2>Our Support Services</h2>
            <p>At SmeadIT, we're ready to help you both in person and online. After you request help, we'll get in touch to confirm a time that works for you, whether it's an in-person meeting or an online session.</p>
            <Link to="/contact"><button className="service-btn">Request Help</button></Link>
          </div>
          <div className="info-image-container">
            <img src={ServiceIcon2} alt="Easy-to-Use Web Design" className="info-image" />
          </div>
        </div>
        <div className="info-block">
          <div className="info-image-container">
            <img src={ServiceIcon3} alt="Innovative Solutions" className="info-image" />
          </div>
          <div className="info-text-container">
            <h2>Looking for More Information?</h2>
            <p>If you're curious about all the services we offer, consider getting in touch. For more details on our services and support options.</p>
            <Link to="/about"><button className="service-btn">Learn More</button></Link>
          </div>
        </div>
      </section>

      <br/><br/>
      <h1 className='titleName'>FAQ`s!</h1>
      <br/><br/>
      <DropDown/>

      <br/><br/>
      <section className="feedback-section">
        <h2 className="feedback-title">Client Feedback</h2>
        <div className="feedback-container">
          <div className="feedback-block">
            <h4>-Sandii</h4>
            <p>"Darcy, you have a lovely manner and are very easy to talk to. You don't make the client feel stupid or uneducated. You inform them of how things work, how to rectify them as well as being respectful and very well mannered. I will certainly get you again for any tech needs I may have."</p>
          </div>
          <div className="feedback-block">
            <h4>- Norma Kelly </h4>
            <p>"Excellent service, 5 stars, well and intelligently explained, with fun analogies. Darcy is skilful with communicating ideas"</p>
          </div>
          <div className="feedback-block">
            <h4>- Natalie Cook </h4>
            <p>"I had been working on a technical issue on my computer for many hours and couldn’t find a solution.  I called Darcy who came out that same day and resolved my issue within 15 minutes.  I was so relieved. Darcy was polite, punctual and explained to me what the issue was.  He patiently worked through the options with me and resolved it quickly.  He saved me many hours of stress. I would highly recommend SmeadIT."</p>
          </div>
        </div>
        <a href='https://g.page/r/CYgA3cXRwLVPEBM/review' target='_blank'><button className="write-review-btn">Write a Review</button></a> 
      </section>

     
    </div>
  );
};

export default Home;
