import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Custom hooks and scripts
import { ScrollToTop } from './scripts/useScrollToTop.js'; 

// Firebase Authentication
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Stripe integration
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Navigation components
import Navbar from './components/Navs/Nav.js';

import Footer from './components/Navs/Footer.js';

// Modal and popup components
import HelpPopup from './components/Modals/HelpPopup.js';
import MessagePopup from './components/Modals/MessageButton.js';
import Modal from './components/Modals/Modal.js';
import ComingSoon from './components/Modals/ComingSoon.js';

// Page components
import Home from './pages/Home.js';
import About from './pages/About.js';
import Contact from './components/Forms/Contact.js';
import Getstarted from './pages/GetStarted.js';
import Features from './pages/Features.js';

import ServicePrices from './pages/ServicePrices.js';
import WebDevelopment from './pages/WebDevelopment.js';
import Webinars from './pages/WebinarsInfo.js';
import ServiceList from './pages/ServiceList.js'
import Refers from './components/Forms/Refer.js';



// Authentication pages



// Feature components
import Quote from './components/Forms/Quote.js';
import Updates from './components/Features/ClientViewUpdates.js';
import Blogs from './components/Features/ClientViewBlog.js';
import BlogDetail from './components/Features/BlogDetail.js';
import MyRequests from './components/Forms/MyRequests.js';
import AddClient from './components/Forms/AddClient.js';
import CustomerService from './components/Forms/CustomerService.js';
import WebForm from './components/Forms/WebForm.js';


// Placeholder for future development
import PageUpdates from './components/Modals/ComingSoon.js';
import NotFound from './components/Modals/ComingSoon.js';

// Go Pages
import Goals from './GoPages/OurGoals.js';

// Stripe promise initialization
const stripePromise = loadStripe('pk_live_51OUJ44IGXy7izLCvcomjlsENXScrOujxzbG0gVrXAIWqpLtYugbzxqf7gfVCSBmehTl8BS2WLdCfrFwsrRVgiwdJ007mNr3PgM');

const App = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth();

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     setIsLoggedIn(!!user);
  //   });
  //   return () => unsubscribe();
  // }, [auth]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
    <HelmetProvider>
      <ScrollToTop />
      <div className="App">
      

        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <p>This is a modal message.</p>
        </Modal>
        <Navbar />

        <HelpPopup />
        <MessagePopup />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/smeadit" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
          <Route path="/webinars" element={<Webinars/>} /> 
          <Route path="/servicelist" element={<ServiceList/>} />
          <Route path="/refers" element={<Refers />} />
          <Route path="/refer" element={<Refers />} />
          
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
     
  
          <Route path="/shop" element={<ComingSoon />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/getstarted" element={<Getstarted />} />
          <Route path="/booking" element={<Quote />} />
          <Route path="/terms-and-conditions" element={<Home />} />
       

    
          <Route path="/updates" element={<Updates />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blogs/:blogId" element={<BlogDetail />} />
          <Route path="/myrequests" element={<MyRequests />} />
          
          <Route path="/features" element={<Features />} />
          <Route path="/services" element={<ServiceList/>} />
          <Route path="/webform" element={<WebForm />} />
       

       
          <Route path="/serviceprices" element={<ServicePrices />} />
          <Route path="/pageupdates" element={<PageUpdates />} />

        
          <Route path="/addclient" element={<AddClient />} />
          <Route path="/customerservice" element={<CustomerService />} />

          <Route path="*" element={<NotFound />} />

          // Go Pages
          <Route path="/goals" element={<Goals />} />

        </Routes>
        <Footer />
      </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
